@import "../../styles/colors";

.button {
  background-color: $secondary;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  color: $white;
  padding: 10px 25px;
  font-weight: bold;

  &:hover {
    background-color: lighten($secondary, 10%);
  }

  &--icon {
    @extend .button;
    width: 35px;
    height: 35px;
    padding: 0;
    background-color: $button-background;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &:hover {
      background-color: lighten($button-background, 10%);
    }
  }

  &--red {
    background-color: $button-red;

    &:hover {
      background-color: lighten($button-red, 30%);
    }
  }

  &--green {
    background-color: $button-green;

    &:hover {
      background-color: lighten($button-green, 30%);
    }
  }

  &--text {
    @extend .button;
    display: inline;
    padding: 0;
    background-color: transparent;
    color: $secondary;
    text-decoration: underline;
    font-size: 0.8em;

    &:hover {
      background-color: transparent;
      color: lighten($secondary, 30%);
    }
  }
}
