@import "../../styles/colors";
@import "../../styles/variables";

.users__container {
  height: 100%;
  width: 100%;
  padding: 15px 30px;

  @media #{$min-medium} {
    margin-left: 15px;
  }

  .badge {
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }
  }

  .user-list {
    gap: 0;
  }
}