@import "../../styles/colors";
@import "../../styles/variables";

.codes-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 15px;
  min-height: 0;

  @media #{$max-medium} {
    flex-direction: column;
    gap: 30px;
  }

  @media #{$min-medium} {
    margin-left: 15px;
  }
}

.add-code-form {
  box-sizing: border-box;
  background-color: transparent;
  box-shadow: none;
  padding: 10px;
}