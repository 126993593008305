@import '../../styles/colors';

.badge {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  &__avatar img {
    width: 45px;
    height: 45px;
    border-radius: 10px;
  }

  &__title {
    font-weight: bold;
    display: flex;align-items: center;
  }

  &__subtitle {
    font-size: 0.8em;
    color: $gray;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
  }

  &--round-avatar img {
    border-radius: 50%;
  }
}

.user-banned-icon {
  font-size: 1em;
  color:$red;
  margin: 0 4px;
}