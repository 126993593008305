@import '../../styles/colors';

.navigation {

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-item {
    width: 100%;
    padding: 5px 0;
  }

  &__icon {
    margin-right: 20px;

    &--end {
      margin-left: auto;
    }
  }

  &__link {
    color: $white;
    text-decoration: none;
    padding: 15px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $button-background;
    }
  }
}