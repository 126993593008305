@import '../../styles/_colors';
@import '../../styles/variables';

.login-form {
  width: 25%;
  min-width: 200px;
  box-sizing: border-box;

  @media #{$max-medium} {
    width: 95%;
  }
}