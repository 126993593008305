@mixin shadow {
  -webkit-box-shadow: 0px 0px 8px 4px rgba(209,209,209,1);
  -moz-box-shadow: 0px 0px 8px 4px rgba(209,209,209,1);
  box-shadow: 0px 0px 8px 4px rgba(209,209,209,1);
}

@mixin shadow2 {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(209,209,209,1);
  -moz-box-shadow: 0px 0px 8px 4px rgba(209,209,209,1);
  box-shadow: 0px 0px 15px 0px rgba(209,209,209,0.5);
}