@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './styles/colors';
@import './styles/variables';

html, body, #root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: $silver;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $primary;
}

.scrollbar--orange::-webkit-scrollbar-thumb {
  background-color: $secondary;
}

.hide {
  @media #{$max-medium} {
    display: none;
  }
}