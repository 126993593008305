@import '../../styles/variables';

.list-container {
  width: 100%;
  height: 100%;
  padding: 20px;

  @media #{$min-medium} {
    margin-left: 15px;
  }
}
