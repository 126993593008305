@import '../../styles/colors';
@import '../../styles/variables';

.container {
  padding: 20px;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: $background;
  display: flex;
  flex-direction: row;

  @media #{$max-medium} {
    flex-direction: column;
    gap: 20px;
    height: auto;
  }
  
  &--center-content {
    @extend .container;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media #{$min-medium} {
    margin-left: 15px;
  }

  @media #{$max-medium} {
    gap: 20px;
  }

  &__section {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__section-title {
    margin: 0 0 20px 0;

    @media #{$max-medium} {
      text-align: center;
    }
  }

  &__widgets {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 15px;
    min-height: 0;

    @media #{$max-medium} {
      flex-direction: column;
      gap: 30px;
    }
  }
}

.center {
  align-self: center;
}