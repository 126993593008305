@import "../../styles/colors";

.add-user {
  &__form {
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__text-field {
    &--title {
      font-size: large;
    }
    font-size: 20px;
  }

  &__button {
    width: 100%;
  }

  &__button-container {
    margin-top: auto;
  }
}

.message {
  color: $green;
}