@import "../../styles/colors";
@import "../../styles/variables";

.log-list {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;

  &__item {
    padding: 8px 0;
    border-bottom: 1px solid $separator-line;

    span:first-child {
      width: 25%;
      min-width: 200px;
      display: inline-block;
    }

    &--heading {
      @extend .log-list__item;
      font-weight: bold;

      @media #{$max-medium} {
        display: none;
      }
    }
  }
}

.end-message {
  text-align: center;
  font-weight: bold;
}