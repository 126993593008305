@import '../../styles/colors';
@import '../../styles/variables';

.sidebar {
  background-color: $primary;
  padding: 20px;
  border-radius: 15px;
  color: $white;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20%;
  min-width: 250px;

  @media #{$max-medium} {
    width: 100%;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    font-size: 1.75em;
    text-align: center;
  }
}