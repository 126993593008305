@import '../../styles/colors';

.user-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}
