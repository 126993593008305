@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body,#root{height:100%}::-webkit-scrollbar{width:6px;height:6px}::-webkit-scrollbar-track{background-color:#e2e2e2;border-radius:10px}::-webkit-scrollbar-thumb{border-radius:10px;background-color:#323484}.scrollbar--orange::-webkit-scrollbar-thumb{background-color:#f25900}@media screen and (max-width: 1200px){.hide{display:none}}

.log-list{list-style:none;margin:0;padding:0;flex:1 1}.log-list__item,.log-list__item--heading{padding:8px 0;border-bottom:1px solid #dbdbdb}.log-list__item span:first-child,.log-list__item--heading span:first-child{width:25%;min-width:200px;display:inline-block}.log-list__item--heading{font-weight:bold}@media screen and (max-width: 1200px){.log-list__item--heading{display:none}}.end-message{text-align:center;font-weight:bold}

.button,.button--icon,.button--text{background-color:#f25900;cursor:pointer;border-radius:5px;border:0;color:#fff;padding:10px 25px;font-weight:bold}.button:hover,.button--icon:hover,.button--text:hover{background-color:#ff7626}.button--icon{width:35px;height:35px;padding:0;background-color:rgba(92,94,204,0.6);display:flex;justify-content:center;align-items:center;font-weight:bold;font-size:1.2em}.button--icon:not(:last-child){margin-right:15px}.button--icon:hover{background-color:rgba(131,132,216,0.6)}.button--red{background-color:rgba(180,29,36,0.6)}.button--red:hover{background-color:rgba(234,128,132,0.6)}.button--green{background-color:rgba(19,100,80,0.6)}.button--green:hover{background-color:rgba(55,217,177,0.6)}.button--text{display:inline;padding:0;background-color:transparent;color:#f25900;text-decoration:underline;font-size:0.8em}.button--text:hover{background-color:transparent;color:#ffb68c}

.badge{display:flex;flex-direction:row;grid-gap:15px;gap:15px;align-items:center}.badge__avatar img{width:45px;height:45px;border-radius:10px}.badge__title{font-weight:bold;display:flex;align-items:center}.badge__subtitle{font-size:0.8em;color:#bebebe}.badge__actions{display:flex;align-items:center;grid-gap:5px;gap:5px;margin-left:auto}.badge--round-avatar img{border-radius:50%}.user-banned-icon{font-size:1em;color:#da3434;margin:0 4px}

.user-list{padding:0;margin:0;display:flex;flex-direction:column;grid-gap:10px;gap:10px;flex-grow:1}

.widget{box-shadow:0px 0px 15px 0px rgba(209,209,209,0.5);display:flex;flex-direction:column;border-radius:15px;padding:15px;box-sizing:border-box;overflow:auto;background-color:#fff}@media screen and (max-width: 1200px){.widget{width:100% !important}}.widget--center{justify-content:space-evenly;align-items:center}

.container,.container--center-content{padding:20px;height:100%;min-height:100%;box-sizing:border-box;background-color:#F4F8FB;display:flex;flex-direction:row}@media screen and (max-width: 1200px){.container,.container--center-content{flex-direction:column;grid-gap:20px;gap:20px;height:auto}}.container--center-content{justify-content:center;align-items:center;height:100%}.dashboard{width:100%;display:flex;flex-direction:column;grid-gap:10px;gap:10px}@media screen and (min-width: 1200px){.dashboard{margin-left:15px}}@media screen and (max-width: 1200px){.dashboard{grid-gap:20px;gap:20px}}.dashboard__section{height:50%;display:flex;flex-direction:column;align-items:stretch}.dashboard__section-title{margin:0 0 20px 0}@media screen and (max-width: 1200px){.dashboard__section-title{text-align:center}}.dashboard__widgets{flex-grow:1;display:flex;flex-direction:row;align-items:stretch;grid-gap:15px;gap:15px;min-height:0}@media screen and (max-width: 1200px){.dashboard__widgets{flex-direction:column;grid-gap:30px;gap:30px}}.center{align-self:center}

.form{box-shadow:0px 0px 8px 4px #d1d1d1;color:#fff;padding:30px;border-radius:15px;background-color:#323484;text-align:center;box-sizing:border-box}.form__group{padding:10px 0}.form__title,.form__title--small{font-family:'Montserrat', sans-serif;font-size:2.5em;margin:0;padding:0}.form__title--small{font-size:1.2em}.form__textfield{padding:5px 10px;border-radius:5px;border:0;width:100%;box-sizing:border-box;font-size:1.1em}.form__label{display:block;text-align:left;font-size:0.9em;margin-bottom:5px}.form__separator{margin-top:20px;width:100%;display:flex;align-items:center}.form__separator-line{width:100%;height:1px;background-color:#bebebe;flex-grow:1}.form__separator-text{padding:0 15px;font-size:0.9em;flex-shrink:0}.form__text-to-copy{padding:10px;border-radius:5px;color:#000;font-size:0.85em;background-color:rgba(255,255,255,0.4);text-align:left;min-height:calc(4em + 20px)}.form__text-to-copy--empty{color:#888}

.add-user__form{color:#fff;display:flex;flex-direction:column;height:100%}.add-user__text-field{font-size:20px}.add-user__text-field--title{font-size:large}.add-user__button{width:100%}.add-user__button-container{margin-top:auto}.message{color:#38bb38}

@media screen and (max-width: 1200px){.open-gate-widget{grid-gap:10px;gap:10px}}.open-gate-widget__title{color:#fff;font-weight:bold;font-size:3vh;text-align:center}.open-gate-widget__button,.open-gate-widget__button--disabled{cursor:pointer;border:0;color:#fff;display:flex;justify-content:center;align-items:center;font-size:80px;width:15vh;height:15vh;border-radius:50%;background-color:#fff;color:#f25900}.open-gate-widget__button:hover,.open-gate-widget__button--disabled:hover{background-color:#ffe1d9;color:#d95000}.open-gate-widget__button--disabled{background-color:#bebebe;color:#8c3300;cursor:no-drop}.open-gate-widget__button--disabled:hover{background-color:#bebebe;color:#8c3300}

.error{color:#da3434;font-size:0.9em}

.login-form{width:25%;min-width:200px;box-sizing:border-box}@media screen and (max-width: 1200px){.login-form{width:95%}}

.list-container{width:100%;height:100%;padding:20px}@media screen and (min-width: 1200px){.list-container{margin-left:15px}}

.button-container{height:100%;width:100%}@media screen and (min-width: 1200px){.button-container{margin-left:15px}}.button-container .open-gate-widget__title{font-size:8vh}@media screen and (max-width: 1200px){.button-container .open-gate-widget__title{font-size:10vw}}.button-container .open-gate-widget__button,.button-container .open-gate-widget__button--disabled{font-size:25vh;width:45vh;height:45vh}@media screen and (max-width: 1200px){.button-container .open-gate-widget__button,.button-container .open-gate-widget__button--disabled{width:70vw;height:70vw}}

.users__container{height:100%;width:100%;padding:15px 30px}@media screen and (min-width: 1200px){.users__container{margin-left:15px}}.users__container .badge{padding:10px 0}.users__container .badge:not(:last-child){border-bottom:1px solid #bebebe}.users__container .user-list{grid-gap:0;gap:0}

.navigation__list{list-style:none;padding:0}.navigation__list-item{width:100%;padding:5px 0}.navigation__icon{margin-right:20px}.navigation__icon--end{margin-left:auto}.navigation__link{color:#fff;text-decoration:none;padding:15px 10px;border-radius:5px;display:flex;align-items:center}.navigation__link:hover{background-color:rgba(92,94,204,0.6)}

.sidebar{background-color:#323484;padding:20px;border-radius:15px;color:#fff;height:100%;box-sizing:border-box;display:flex;flex-direction:column;grid-gap:20px;gap:20px;width:20%;min-width:250px}@media screen and (max-width: 1200px){.sidebar{width:100%}}.sidebar__title{font-family:'Montserrat', sans-serif;padding:0;margin:0;font-size:1.75em;text-align:center}

.codes-container{flex:1 1;display:flex;flex-direction:row;align-items:stretch;grid-gap:15px;gap:15px;min-height:0}@media screen and (max-width: 1200px){.codes-container{flex-direction:column;grid-gap:30px;gap:30px}}@media screen and (min-width: 1200px){.codes-container{margin-left:15px}}.add-code-form{box-sizing:border-box;background-color:transparent;box-shadow:none;padding:10px}

.code-list{border-collapse:collapse}.code-list__item,.code-list__item--heading{border:solid;border-width:1px 0;border-color:#dbdbdb}.code-list__item:first-child,.code-list__item--heading:first-child{border-top:none}.code-list__item:last-child,.code-list__item--heading:last-child{border-bottom:none}.code-list__item td,.code-list__item--heading td{padding:10px}.code-list__item--heading{font-weight:bold}.code-list__item-action{display:inline-flex}.icon--green{color:#38bb38}.icon--red{color:#da3434}

