@import '../../styles/colors';
@import '../../styles/mixins';

.form {
  @include shadow;
  color: $white;
  padding: 30px;
  border-radius: 15px;
  background-color: $primary;
  text-align: center;
  box-sizing: border-box;

  &__group {
    padding: 10px 0;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5em;
    margin: 0;
    padding: 0;

    &--small {
      @extend .form__title;
      font-size: 1.2em;
    }
  }

  &__textfield {
    padding: 5px 10px;
    border-radius: 5px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.1em;
  }

  &__label {
    display: block;
    text-align: left;
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  &__separator {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__separator-line {
    width: 100%;
    height: 1px;
    background-color: $gray;
    flex-grow: 1;
  }
  
  &__separator-text {
    padding: 0 15px;
    font-size: 0.9em;
    flex-shrink: 0;
  }

  &__text-to-copy {
    padding: 10px;
    border-radius: 5px;
    color: $black;
    font-size: 0.85em;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: left;
    min-height: calc(4em + 20px);

    &--empty {
      color: #888;
    }
  }
}