@import '../../styles/colors';
@import '../../styles/variables';

.open-gate-widget {
  @media #{$max-medium} {
    gap: 10px;
  }

  &__title {
    color: $white;
    font-weight: bold;
    font-size: 3vh;
    text-align: center;
  }

  &__button {
    cursor: pointer;
    border: 0;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    width: 15vh;
    height: 15vh;
    border-radius: 50%;
    background-color: $white;
    color: $secondary;

    &:hover {
      background-color: #ffe1d9;
      color: darken($secondary, 5%);
    }

    &--disabled {
      @extend .open-gate-widget__button;
      background-color: $gray;
      color: darken($secondary, 20%);
      cursor: no-drop;

      &:hover {
        background-color: $gray;
        color: darken($secondary, 20%);
      }
    }
  }
}