$primary: #323484;
$secondary: #f25900;

$white: #ffffff;
$gray: #bebebe;
$red: #da3434;
$silver: #e2e2e2;
$green: #38bb38;
$black: #000000;

$button-background: rgba(92, 94, 204, 0.6);
$button-green: rgba(19, 100, 80, 0.6);
$button-red: rgba(180, 29, 36, 0.6);
$background: #F4F8FB;
$separator-line: #dbdbdb;