@import '../../styles/colors';
@import '../../styles/variables';



.code-list {
  border-collapse: collapse;

  &__item {
    border: solid;
    border-width: 1px 0;
    border-color: $separator-line;

    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }

    td {
      padding: 10px;
    }

    &--heading {
      @extend .code-list__item;
      font-weight: bold;
    }
  }

  &__item-action {
    display: inline-flex;
  }
}

.icon {
  &--green {
    color: $green;
  }

  &--red {
    color: $red;
  }
}