@import '../../styles/mixins';
@import '../../styles/variables';

.widget {
  @include shadow2;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff;

  @media #{$max-medium} {
    width: 100% !important;
  }

  &--center {
    justify-content: space-evenly;
    align-items: center;
  }
}