@import '../../styles/variables';

.button-container {
  height: 100%;
  width: 100%;

  @media #{$min-medium} {
    margin-left: 15px;
  }

  .open-gate-widget__title {
    font-size: 8vh;

    @media #{$max-medium} {
      font-size: 10vw;
    }
  }

  .open-gate-widget__button,
  .open-gate-widget__button--disabled {
    font-size: 25vh;
    width: 45vh;
    height: 45vh;

    @media #{$max-medium} {
      width: 70vw;
      height: 70vw;
    }
  }
}
